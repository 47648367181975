<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. categorias" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Categoria</label>
                                                <input type="text" v-model="name" class="form-control" :class="{'is-invalid': errors.has('category')}" name="category" v-validate="'required'">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Cor de identificação</label>
                                                <input type="color" class="form-control" value="#000000" v-model="color" name="color" v-validate="'required'" :class="{'is-invalid': errors.has('color')}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Add</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="col-12">
                        <div class="form-group d-flex">
                            <input type="search" v-model="search" class="form-control campo-procurar" placeholder="Procurar">
                            <button type="button" @click="goSearch" class="btn btn-info">Buscar</button>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="needs-validation" novalidate @submit.prevent="update">
                                <div class="form-body">
                                    <table class="table no-wrap v-middle mb-0">
                                        <thead>
                                            <tr class="border-0">
                                                <th class="border-0 text-muted pt-0 px-2 text-center"><span>Cadastrados</span></th>
                                                <th class="border-0 font-14 font-weight-medium text-muted"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in categories" :key="item.id">
                                                <td class="font-weight-medium text-dark border-top-0 px-2">
                                                    <input type="text" class="form-control" placeholder="" v-model="categories[index].name" >
                                                </td>
                                                <td class="border-top-0 text-muted px-0 py-0 font-14">
                                                    <select class="custom-select mr-sm-2" v-model="categories[index].is_active" >
                                                        <option :value="1">Ativa</option>
                                                        <option :value="0">Inativa</option>
                                                    </select>
                                                    <input type="color" class="form-control" v-model="categories[index].color">

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Atualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
const service = KitConfigService.build();

export default {
  data() {
    return {
      name: null,
      color: null,
      categories: [],
      search: '',
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            }
       ],
       menu:[
            {
                name: 'Novo cliente',
                to: 'ClientNew'
            }
       ]
    };
  },
  methods: {
    goSearch(){
        if(this.search.length == 0)
            this.fetchCond();

        this.categories = this.categories.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

        let data = {
          name: this.name,
          color: this.color,
          type: 'CATEGORY'
        }
        
          service
          .create(data)
            .then(resp => {
                this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                  title: 'Configuração criada',
                  autoHideDelay: 5000,
                  type: 'success'
                })
                this.name = '';
                this.$validator.reset();
                this.fetchCategory();
            })
            .catch(err => {
              console.log(err)
            })
          }
      });
    },
    checkField(){
        return this.categories.filter(elem => {
            return elem.name.length == 0;
        }).length;
    },
    update(){

        if(this.checkField()){
            this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                title: 'Campo inválido',
                autoHideDelay: 5000,
                type: 'danger'
            })
            return;
        }

        let data = {
          id: 0,
          config: this.categories
        }
    
        service
        .update(data)
        .then(resp => {
            this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                title: 'Configuração atualizada',
                autoHideDelay: 5000,
                type: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
    },
    fetchCategory(){
        let data = {
            type: 'CATEGORY'
        }

        service
          .search(data)
            .then(resp => {
              this.categories = resp;
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
      this.fetchCategory();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>